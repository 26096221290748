/* Get the header element and it's position */
var header = document.querySelector("header");

window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  /* if scrolling down, let it scroll out of view as normal */
  
  if (currentScrollPos >= header.offsetHeight){
    if(!header.classList.contains('nav-up')){
      header.classList.add("nav-up");
    }
  }
  /* otherwise if we're scrolling up, fix the nav to the top */
  else{  
    header.classList.remove("nav-up");
  }
}

$(function() {
  $(".active_menu", 'header').on("click", function(e) {
      $('header').toggleClass("active"); //you can list several class names 
      $('body').toggleClass("noscroll"); //you can list several class names 
      e.preventDefault();
   });
 });