const Shopify = {};
Shopify.queryParams = {};
if (location.search.length) {
  for (var aKeyValue, i = 0, aCouples = location.search.substr(1).split('&'); i < aCouples.length; i++) {
    aKeyValue = aCouples[i].split('=');
    if (aKeyValue.length > 1) {
      Shopify.queryParams[decodeURIComponent(aKeyValue[0])] = decodeURIComponent(aKeyValue[1]);
    }
  }
}
var collFilters = jQuery('.coll-filter');
collFilters.change(function() {
  var newTags = [];
  var newURL = '';
  collFilters.each(function() {
    if (jQuery(this).val()) {
      newTags.push(jQuery(this).val());
    }
  });
  if (newTags.length) {
    Shopify.queryParams.constraint = newTags.join('+');
  }
  else {
    delete Shopify.queryParams.constraint;
  }
  location.search = jQuery.param(Shopify.queryParams);
});