
class LocationContact extends HTMLElement {
  constructor() {
    super();
    console.log();

    this.tabs = this.querySelector('#pills-tab').querySelectorAll('li');
    this.content = this.querySelector('#pills-content').querySelectorAll('div');
    // პირველები გავააქტიუროთ
    this.tabs[0].classList.add('active')
    this.content[0].classList.add('active')
    // li click event
    this.tabs.forEach((value, key) => {
      value.addEventListener('click', (e) => this.find_and_select_active(e))
    });
  }

  find_and_select_active(event) {
    const id = event.target.dataset?.bsTarget;
    //
    this.content.forEach((value, key) => {
      value.classList.remove('active');
      // გავააქტიუროთ კონკრეტული ტაბი
      if( value.id === id ) {
        value.classList.add('active');
      } 
    })
    this.tabs.forEach((value, key) => {
      value.classList.remove('active');
    })
    //
    event.target.classList.add('active')
  }
}
customElements.define('facet-location-form', LocationContact);