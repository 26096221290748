import Swiper from 'swiper/bundle';


// //メインスライド
// var slider = new Swiper ('.gallery-slider', {
//   slidesPerView: 1,
//   centeredSlides: false,
//   loop: false,
//   spaceBetween: 
//   loopedSlides: 6, //スライドの枚数と同じ値を指定
//   navigation: {
//       nextEl: '.swiper-button-next',
//       prevEl: '.swiper-button-prev',
//   },
// });

// //サムネイルスライド
// var thumbs = new Swiper ('.gallery-thumbs', {
//   slidesPerView: 'auto',
//   spaceBetween: 10,
//   centeredSlides: true,
//   loop: false,
//   slideToClickedSlide: true,
// });

// //3系
// slider.params.control = thumbs;
// thumbs.params.control = slider;

// //4系～
// slider.controller.control = thumbs;
// thumbs.controller.control = slider;


var thumbs = new Swiper(".gallery-thumbs", {
  loop: true,
  spaceBetween: 10,
  slidesPerView: 4,
  freeMode: true,
  watchSlidesProgress: true,
});

var slider = new Swiper(".gallery-slider", {
  loop: true,
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: thumbs,
  },
});